import React from "react"

import { EditorState, convertFromRaw } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

const Content = ({ content }) => {
    const storedState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(content))
    )
    return (
        <div className="page-content">
            <Editor
                editorState={storedState}
                readOnly={true}
                toolbarHidden={true}
            />
        </div>
    )
}

export default Content
