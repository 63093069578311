import React from "react"

const Heading = ({title}) => (
    <>
    <div className="mainheading">
        <h1>{title}</h1>
        <hr id="content-top" className="heading-underline" />
    </div>
    </>
)

export default Heading